//region Imports
import nexity_logo from '../../images/nexity_logo.png'
import oculus_logo from '../../images/oculus_logo.png'
import styles      from '../../styles/Z_Visit_VR.module.sass'
import closeIcon   from '../../svg/close-icon.svg'
//endregion

const Z_Visit_VR = ( { setVrActive } ) => {
	return ( <div
			className={ styles.visit_vr }
			onClick={ ( e ) => {
				e.stopPropagation()
				
				if ( e.target === e.currentTarget ) {
					setVrActive( false )
				}
			} }
	>
		<div className={ styles.container }>
			<div className={ styles.top }>
				<img
						src={ closeIcon }
						className={ styles.close_icon }
						onClick={ () => {
							setVrActive( false )
						} }
						alt={ 'Close icon' }
				/>
				
				<div className={ styles.images }>
					<img
							className={ styles.nexity_logo }
							src={ nexity_logo }
							alt="Logo Nexity"
					/>
					<img
							className={ styles.oculus_logo }
							src={ oculus_logo }
							alt="Logo Oculus"
					/>
				</div>
			</div>
			
			<div className={ styles.download_app }>
				<strong>Téléchargez l’application Nexity depuis le store Oculus</strong> et découvez le logement en
				                                                                         réalité virtuelle avec un
				                                                                         casque, comme si vous y étiez.
			</div>
			
			<div className={ styles.number }>
				N293
			</div>
			
			<div className={ styles.number_information }>
				Au lancement de l’application, rensignez le numéro ci-dessus pour découvrir le logement.
			</div>
		
		</div>
	</div> )
}

export default Z_Visit_VR
