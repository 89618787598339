//region Imports
import { useEffect } from 'react'
import buildingImage from '../images/default-section-building.png'
import styles        from '../styles/Z_Default_Section.module.sass'
//endregion

const Z_Default_Section = ( {
	                            setPageToShow,
	                            setFullScreen
                            } ) => {
	
	//region Handlers
	// Component load -> Set body overflow to auto
	useEffect(
			() => {
				document.body.style.overflow = 'auto'
			},
			[]
	)
	//endregion
	
	return (
			<div className={ styles.default_section }>
				<div className={ styles.top_section }>
					<div className={ styles.text }>
						<h1>Green Eko</h1>
						
						<span>
						2 à 4 pièces à partir<br />
						de 100 000€
					</span>
					</div>
					
					<button
							className={ styles.discover_program }
							onClick={ () => {
								setFullScreen( true )
							}
							}
					>
						Découvrir le programme
					</button>
				</div>
				
				<div className={ styles.building_image_section }>
					<img
							className={ styles.building_image }
							src={ buildingImage }
							alt={ 'Building shown in the background of the section' }
					/>
				</div>
			</div>
	)
}

export default Z_Default_Section
