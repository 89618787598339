//region Imports
import { useGLTF, useHelper }  from '@react-three/drei'
import { gsap }                from 'gsap'
import { button, useControls } from 'leva'
import { useEffect, useRef }   from 'react'
import { PointLightHelper }    from 'three'
//endregion

const Z_Apartment = ( {
	                      // Current selected room
	                      room, // Intensity of the environment light on the materials
	                      envMapIntensity,
	                      setModelLoaded,
	                      roomLightDistanceScale,
	
	                      // Other properties
	                      ...props
                      } ) => {
	
	//region References
	// Room light ref
	const roomLightRef = useRef()
	
	// Apartment model ref
	const apartRef = useRef()
	//endregion
	
	//region Load model
	const {
		      scene,
		      materials
	      } = useGLTF( `${ process.env.PUBLIC_URL }/ThreeJS_Files/nexity_model_baked_v2.glb` )
	//endregion
	
	//region LEVA - Properties
	//region Directional light properties
	const [
		      {
			      showHelper: showDirectionalLightHelper,
			      ...         roomLightProps
		      }, setRoomLightProps
	      ] = useControls(
			'Room light',
			() => ( {
				showHelper: false,
				color:      'white',
				distance:   5,
				decay:      2,
				penumbra:   {
					value: 0,
					min:   0,
					max:   1,
					step:  .001,
				},
				position:   [ 0, 0, 0 ],
				intensity:  2,
			} )
	)
	//endregion
	
	//region Apartment model properties
	const [
		      {
			      ...apartmentProps
		      }, setApartmentProps
	      ] = useControls(
			'Apartment',
			() => ( {
				position:     [ 0, -3, 0 ],
				'rotation-y': {
					value: 0,
					min:   -Math.PI,
					max:   Math.PI,
					step:  Math.PI / 32,
				},
				
				log: button( () => {
					console.log(
							apartRef.current.position,
							apartRef.current.rotation
					)
				} ),
				
				set: button( () => {
					setApartmentProps( {
						                   position:     [
							                   apartRef.current.position.x,
							                   apartRef.current.position.y,
							                   apartRef.current.position.z
						                   ],
						                   'rotation-y': apartRef.current.rotation.y,
					                   } )
				} )
			} ),
			{ collapsed: true }
	)
	//endregion
	//endregion
	
	//region Handlers
	//region Room changed
	// Camera animation
	useEffect(
			() => {
				if ( room ) {
					const timeline = gsap.timeline( {
						                                defaults: {
							                                duration: 2,
						                                }
					                                } )
					
					setRoomLightProps( {
						                   position:  room.light_position,
						                   distance:  room.light_distance + ( ( roomLightDistanceScale - 1 )
						                                                      * room.light_distance
						                                                      / 2 ),
						                   intensity: room.light_intensity,
					                   } )
					
					timeline
							.to(
									apartRef.current.position,
									{
										x: room.position[ 0 ],
										y: room.position[ 1 ] + 5,
										z: room.position[ 2 ],
									}
							)
							.to(
									apartRef.current.rotation,
									{
										x: room.rotation[ 0 ],
										y: room.rotation[ 1 ],
										z: room.rotation[ 2 ],
									},
									'<'
							)
				}
			},
			[ room, roomLightDistanceScale ]
	)
	//endregion
	
	//region Materials or envMapIntensity changed
	useEffect(
			() => {
				// Set material envMapIntensity : intensity of the environment light on the materials
				Object.entries( materials ).forEach( ( [ key, mat ] ) => {
					mat.envMapIntensity = envMapIntensity
				} )
			},
			[ envMapIntensity, materials ]
	)
	
	useEffect(
			() => {
				const floor_and_walls_material       = materials[ "Baked walls" ]
				floor_and_walls_material.flipY       = false
				floor_and_walls_material.needsUpdate = true
			},
			[ materials ]
	)
	// endregion
	
	//region Model loaded
	useEffect(
			() => {
				if ( scene ) {
					setModelLoaded( true )
				}
			},
			[ scene ]
	)
	//endregion
	// endregion
	
	//region Helpers
	useHelper(
			showDirectionalLightHelper && roomLightRef,
			PointLightHelper
	)
	//endregion
	
	return ( <>
		{/* Light */ }
		<pointLight
				ref={ roomLightRef } { ...roomLightProps }
		/>
		
		{/* Model */ }
		<primitive
				ref={ apartRef }
				object={ scene }
				
				// LEVA Properties
				{ ...apartmentProps }
				
				// Default properties
				{ ...props }
		/>
	</> )
}

export default Z_Apartment
