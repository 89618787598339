//region Imports
import cn                                     from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import 'swiper/css';
import { Swiper, SwiperSlide }                from 'swiper/react'
import Z_Room_Canvas                          from '../../components/room/Z_Room_Canvas'
import styles                                 from '../../styles/Z_Room.module.sass'
import Z_Default_Canvas                       from '../Z_Default_Canvas'
import Z_Contact_Us                           from './Z_Contact_Us'
import goBack                                 from '../../svg/go-back.svg'
import Z_Visit_VR                             from './Z_Visit_VR'
//endregion

const Z_Room_Page = ( { setPageToShow } ) => {
	
	//region References
	const canvasRef = useRef()
	//endregion
	
	//region States
	// Selected room id
	const [ roomId, setRoomId ]                   = useState( 1 )
	// All rooms data
	const [ rooms, setRooms ]                     = useState( [] )
	// Contact us section active or not
	const [ contactUsActive, setContactUsActive ] = useState( false )
	const [ vrActive, setVrActive ]               = useState( false )
	
	// Data shown in the top section
	const [ topData, setTopData ] = useState( {
		                                          numberOfRooms: 2,
		                                          surface:       58,
		                                          price:         '312 000',
	                                          } )
	//endregion
	
	//region Handlers
	// Component load -> Get all rooms data
	useEffect(
			() => {
				document.body.style.overflow        = 'hidden'
				document.body.style.backgroundColor = '#eef3f7'
				
				const loadRooms = async () => {
					const res  = await fetch( `${ process.env.PUBLIC_URL }/rooms.json` )
					const data = await res.json()
					
					setRooms( data )
				}
				
				loadRooms().then()
			},
			[]
	)
	
	/**
	 * Handle room clicked -> set selected room id
	 * @param selectedRoomId The id of the selected room
	 */
	function handleRoomClicked( selectedRoomId ) {
		setRoomId( selectedRoomId )
	}
	
	//endregion
	
	return ( <>
		{/* region Overlay */ }
		<div className={ styles.overlay }>
			<div
					className={ styles.go_back }
					onClick={ () => {
						setPageToShow( 'building-page' )
					} }
			>
				<img
						className={ 'go_back' }
						src={ goBack }
						alt={ 'Go back' }
				/>
			</div>
			
			<div className={ styles.top }>
				<div className={ styles.number_of_rooms_selector }>
					<span className={ styles.text }>{ topData.numberOfRooms } pièces</span>
				</div>
				<div className={ styles.room_information }>
					{ topData.numberOfRooms } pièces à partir de <strong>{ topData.surface } m²</strong><br />
					                          à partir de <strong>{ topData.price }€</strong>
				</div>
			</div>
			
			<div className={ styles.bottom }>
				<Swiper
						className={ styles.other_projects }
						spaceBetween={ 8 }
						slidesPerView={ 'auto' }
				>
					{ rooms && rooms.map( room => ( <SwiperSlide
							key={ room.id }
							className={ cn(
									styles.button,
									{ [ styles.selected ]: room.id === roomId }
							) }
							onClick={ () => handleRoomClicked( room.id ) }
					>
						<span className={ styles.text }>{ room.name }</span>
					</SwiperSlide> ) ) }
				</Swiper>
				
				<div className={ styles.divider }></div>
				
				<div className={ styles.second_line }>
					<button
							className={ styles.learn_more }
							onClick={ () => {
								setContactUsActive( true )
							} }
					>
						Télécharger le plan
					</button>
					
					<button
							className={ styles.visit_vr }
							onClick={ () => {
								setVrActive( true )
							} }
					>
						Visiter en VR
					</button>
				</div>
			</div>
			
			{ contactUsActive && <Z_Contact_Us setContactUsActive={ setContactUsActive } /> }
			{ vrActive && <Z_Visit_VR setVrActive={ setVrActive } /> }
		</div>
		{/* endregion */ }
		
		{/* Canvas */ }
		<Z_Default_Canvas
				canvasStyle={ styles.canvas }
				canvasRef={ canvasRef }
		>
			<Z_Room_Canvas
					room={ rooms.find( elt => elt.id === roomId ) }
					canvasRef={ canvasRef }
			/>
		</Z_Default_Canvas>
	</> )
}

export default Z_Room_Page
