//region Imports
import { Plane, RoundedBox, useGLTF } from '@react-three/drei'
import { useControls }                from 'leva'
//endregion

//region Trees
const Z_Tree_1 = ( {
	                   ...props
                   } ) => {
	const {
		      nodes,
		      materials
	      } = useGLTF( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_01.gltf` )
	return ( <group
			{ ...props }
			dispose={ null }
	>
		<mesh
				geometry={ nodes.Tree_04001.geometry }
				material={ materials.Trunk }
		>
			<mesh
					geometry={ nodes.Tree_04_leaves001.geometry }
					material={ materials.Leaves }
					position={ [ -0.02, 1, -0.01 ] }
			/>
		</mesh>
	</group> )
}

const Z_Tree_2 = ( {
	                   ...props
                   } ) => {
	const {
		      nodes,
		      materials
	      } = useGLTF( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_02.gltf` )
	return ( <group
			{ ...props }
			dispose={ null }
	>
		<mesh
				geometry={ nodes.Tree_06001.geometry }
				material={ materials.Trunk }
		>
			<mesh
					geometry={ nodes.Tree_06_leaves001.geometry }
					material={ materials.Leaves }
					position={ [ -0.02, 0.85, 0.02 ] }
			/>
		</mesh>
	</group> )
}

const Z_Tree_3 = ( {
	                   ...props
                   } ) => {
	const {
		      nodes,
		      materials
	      } = useGLTF( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Tree_03.gltf` )
	return ( <group
			{ ...props }
			dispose={ null }
	>
		<mesh
				geometry={ nodes.Tree_07001.geometry }
				material={ materials.Trunk }
		>
			<mesh
					geometry={ nodes.Tree_07_leaves001.geometry }
					material={ materials.Leaves }
					position={ [ -0.02, 0.85, 0.02 ] }
			/>
		</mesh>
	</group> )
}
//endregion

const Z_Building_Trees = () => {
	
	//region Leva controls
	//region Road props
	// General road props
	const { color: roadColor } = useControls(
			'Road',
			{
				color: '#C6D6E3'
			},
			{ collapsed: true }
	)
	
	// Front road props
	const roadFrontProps = useControls(
			'Road front',
			{
				args:       [ 5.5, .8, .1 ],
				position:   [ .3, 0, 2.45 ],
				rotation:   [ -Math.PI / 2, 0, 0 ],
				radius:     .05,
				smoothness: 4,
			},
			{ collapsed: true }
	)
	
	// Right road props
	const roadRightProps = useControls(
			'Road right',
			{
				args:       [ 5.5, .8, .1 ],
				position:   [ 2.7, 0, .1 ],
				rotation:   [ -Math.PI / 2, 0, Math.PI / 2 ],
				radius:     .05,
				smoothness: 4,
			},
			{ collapsed: true }
	)
	
	// Lines props
	const linesProps = useControls(
			'Road lines',
			{
				args:                 [ .5, .05 ],
				gap:                  .72,
				defaultPositionFront: 2.47,
				defaultPositionRight: 2.2,
				count:                7,
			},
			{ collapsed: true }
	)
	//endregion
	//endregion
	
	return ( <group>
		<Plane
				name={ 'floor' }
				args={ [ 15, 15 ] }
				rotation-x={ -Math.PI / 2 }
				position={ [ 3, -.01, 3 ] }
				position-y={ -.01 }
				receiveShadow
		>
			{/* <meshStandardMaterial
			 color={ '#EEF3F7' }
			 /> */ }
			<shadowMaterial
					opacity={ .1 }
			/>
		</Plane>
		<group name={ 'road' }>
			<RoundedBox { ...roadFrontProps }>
				<meshBasicMaterial color={ roadColor } />
			</RoundedBox>
			<RoundedBox { ...roadRightProps }>
				<meshBasicMaterial color={ roadColor } />
			</RoundedBox>
			
			{/* Lines */ }
			{/* Front */ }
			<group name={ 'lines' }>
				<group name={ 'lines_front' }>
					{ Array.from(
							Array( linesProps.count ),
							( _, index ) => (
									<Plane
											key={ index }
											rotation-x={ -Math.PI / 2 }
											args={ linesProps.args }
											position={ [
												linesProps.defaultPositionFront - ( index * linesProps.gap ),
												.051,
												2.425
											] }
									>
										<meshBasicMaterial color={ 'white' } />
									</Plane>
							)
					) }
				</group>
				<group name={ 'lines_right' }>
					{ Array.from(
							Array( linesProps.count ),
							( _, index ) => (
									<Plane
											key={ index }
											rotation-x={ -Math.PI / 2 }
											args={ [ linesProps.args[ 1 ], linesProps.args[ 0 ] ] }
											position={ [
												2.7,
												.051,
												linesProps.defaultPositionRight - ( index * linesProps.gap )
											] }
									>
										<meshBasicMaterial color={ 'white' } />
									</Plane>
							)
					) }
				</group>
			</group>
		</group>
		<group name={ 'trees' }>
			<Z_Tree_3
					position={ [ -2, 0, 3.7 ] }
					scale={ .6 }
			/>
			<Z_Tree_1
					position={ [ -.9, 0, 3.4 ] }
					scale={ .5 }
			/>
			<Z_Tree_2
					position={ [ 0, 0, 3.5 ] }
					scale={ .6 }
			/>
			<Z_Tree_1
					position={ [ 1, 0, 3.6 ] }
					scale={ .7 }
			/>
			<Z_Tree_3
					position={ [ 2, 0, 3.6 ] }
					scale={ .6 }
			/>
			<Z_Tree_2
					position={ [ 3.2, 0, 3.4 ] }
					scale={ .8 }
			/>
			<Z_Tree_3
					position={ [ 3.5, 0, 2 ] }
					scale={ .6 }
			/>
			<Z_Tree_1
					position={ [ 3.3, 0, 1 ] }
					scale={ .5 }
			/>
			<Z_Tree_2
					position={ [ 3.6, 0, -.3 ] }
					scale={ .8 }
			/>
			<Z_Tree_3
					position={ [ 3.4, 0, -1.5 ] }
					scale={ .8 }
			/>
		</group>
	</group> )
}

export default Z_Building_Trees
