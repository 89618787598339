//region Imports
import { FaCheck } from 'react-icons/fa'
import styles      from '../../styles/Z_Contact_Us.module.sass'
import closeIcon   from '../../svg/close-icon.svg'
//endregion

const Z_Contact_Us = ( { setContactUsActive } ) => {
	return ( <div
			className={ styles.contact_us }
			onClick={ ( e ) => {
				e.stopPropagation()
				
				if ( e.target === e.currentTarget ) {
					setContactUsActive( false )
				}
			} }
	>
		<div className={ styles.container }>
			<img
					src={ closeIcon }
					className={ styles.close_icon }
					onClick={ () => {
						setContactUsActive( false )
					} }
					alt={ 'Close icon' }
			/>
			
			<div className={ styles.top }>
				<span className={ styles.title }>Discuter avec nos conseillers.</span>
				<span className={ styles.phone }>0805 017 004</span>
			</div>
			
			<div className={ styles.form }>
				<div className={ styles.inputs }>
					<div className={ styles.big }>
						{/* Last name */ }
						<input
								type="text"
								placeholder="Nom"
								required
						/>
						{/* First name */ }
						<input
								type="text"
								placeholder="Prénom"
								required
						/>
						{/* Email address */ }
						<input
								type="email"
								placeholder="Adresse mail"
								required
						/>
						{/* Phone number */ }
						<input
								type="tel"
								placeholder="Téléphone"
								required
						/>
						{/* Message (optional)s */ }
						<textarea
								placeholder="Message facultatif"
								rows={ 4 }
						/></div>
					
					<div className={ styles.small }>
						{/* Data usage */ }
						<label>
							<input
									type='checkbox'
									name='data_usage'
							/>
							
							<div className={ styles.indicator }>
								<FaCheck />
							</div>
							
							<span className={ styles.text }>
								J’accepte que mes données soient traitées par Edouard Denis, et/ou ses partenaires dans le cadre de ma demande et de la relation commerciale qui pourrait en découler*
							</span>
						</label>
						
						{/* Offers */ }
						<div className={ styles.offers }>
					<span className={ styles.title }>
						Je souhaite recevoir les offres personnalisées du groupe Nexity*
					</span>
							<div className={ styles.inputs }>
								<label>
									<input
											type="radio"
											name='offers'
											value='Oui'
									/>
									<div className={ styles.indicator }>
										<FaCheck />
									</div>
									<span className={ styles.text }>Oui</span>
								</label>
								<label>
									<input
											type="radio"
											name='offers'
											value='Non'
									/>
									<div className={ styles.indicator }>
										<FaCheck />
									</div>
									<span className={ styles.text }>Non</span>
								</label>
							</div>
						</div>
						
						{/* Partners offers */ }
						<div className={ styles.partners_offers }>
					<span className={ styles.title }>
						Je souhaite recevoir les offres personnalisées des partenaires du groupe Nexity*
					</span>
							
							<div className={ styles.inputs }>
								<label>
									<input
											type="radio"
											name='partners-offers'
											value='Oui'
									/>
									<div className={ styles.indicator }>
										<FaCheck />
									</div>
									<span className={ styles.text }>Oui</span>
								</label>
								<label>
									<input
											type="radio"
											name='partners-offers'
											value='Non'
									/>
									<div className={ styles.indicator }>
										<FaCheck />
									</div>
									<span className={ styles.text }>Non</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<button className={ styles.send_button }>Envoyer ma demande</button>
			</div>
			
			<div className={ styles.legal_mentions }>
				Mentions légales :
				Nexity, en sa qualité de responsable de traitement, réalise des traitements de données à
				caractère
				personnel. Ces traitements sont rendus nécessaires pour répondre à votre demande de contact et
				sont
				fondés sur votre consentement préalable. Les données collectées sont destinées aux services
				concernés du
				groupe Nexity, et le cas échéant à ses sous-traitants et prestataires. Les sous-traitants et
				prestataires en question sont soumis à une obligation de confidentialité et ne peuvent utiliser
				vos
				données qu&apos;en conformité avec nos dispositions contractuelles et la législation applicable.
				Elles
				sont
				conservées pendant une durée ne pouvant excéder 3 ans à compter du dernier contact de votre
				part. Vous
				avez la possibilité d&apos;exercer vos droits pour accéder, rectifier, effacer vos données,
				limiter
				leurs
				traitements, vous y opposer et demander la portabilité de celles-ci. Vous pouvez également
				définir des
				directives relatives à la conservation, à l&apos;effacement et à la communication de vos données
				à
				caractère
				personnel après votre décès. Ces droits s&apos;exercent via le lien suivant :
				https://app.witik.io/fr/form/nexity/exercice-des-droits-rgpd ou par courrier postal à
				l&apos;attention
				du
				Délégué à la Protection des Données (DPO) - Nexity SA - 19 Rue de Vienne - TSA 50029 - 75801
				PARIS Cedex
				08, accompagné d’une copie d’un titre d’identité. Vous pouvez également adresser une réclamation
				auprès
				de la Commission Nationale de l&apos;Informatique et des Libertés - 3 place de Fontenoy - TSA
				80715 -
				75334
				PARIS Cedex 07. Enfin, nous vous informons de l&apos;existence d&apos;une liste
				d&apos;opposition au
				démarchage
				téléphonique prévue par le Code de la Consommation à l&apos;article L.223-1.
			</div>
		</div>
	</div> )
}

export default Z_Contact_Us
