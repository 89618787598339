import { Backdrop, ContactShadows, useCursor, useGLTF } from '@react-three/drei'
import { useGesture }                                   from '@use-gesture/react'
import { useEffect, useRef, useState }                  from 'react'
import { animated, useSpring }                          from '@react-spring/three'

const Z_Building = ( {
	                     setModelLoaded,
	                     buildingScale,
	
	                     ...props
                     } ) => {
	
	// region Load model
	const {
		      nodes,
		      materials
	      } = useGLTF( `${ process.env.PUBLIC_URL }/ThreeJS_Files/Building.glb` )
	// endregion
	
	//region Hovered state
	// Store hovered state
	const [ hovered, setHovered ] = useState( true )
	// Use hovered state to show cursor pointer
	useCursor( hovered )
	//endregion
	
	//region Handlers
	useEffect(
			() => {
				if ( nodes ) {
					setModelLoaded( true )
				}
			},
			[ nodes ]
	)
	//endregion
	
	return ( <>
		<group
				{ ...props }
		>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_2.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 248.03, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wall_floor_2.geometry }
					material={ materials[ "black brick" ] }
					position={ [ -513.64, 248.03, 312.86 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.black_frame_floor_2.geometry }
					material={ materials[ "black wall" ] }
					position={ [ -394.66, 181.89, 337.72 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_2.geometry }
					material={ materials[ "window metal" ] }
					position={ [ -460.33, 643.27, 350.86 ] }
					rotation={ [ Math.PI, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_2.geometry }
					material={ materials[ "window glass 1floor" ] }
					position={ [ -180.56, 643.27, 349.88 ] }
			/>
			<group position={ [ -472.06, 428.51, 412.52 ] }>
				<mesh
						castShadow
						geometry={ nodes.mesh_5.geometry }
						material={ materials[ "black wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_5_1.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_4.geometry }
					material={ materials[ "window metal" ] }
					position={ [ -632.7, 883.62, 426.45 ] }
					rotation={ [ Math.PI, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_4.geometry }
					material={ materials[ "window glass" ] }
					position={ [ -282.84, 883.62, 425.47 ] }
			/>
			<group
					position={ [ -328.13, 367.72, 237.99 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_8.geometry }
						material={ materials.wood }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_8_1.geometry }
						material={ materials[ "white wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_8_2.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_4.geometry }
					material={ materials[ "white wall" ] }
					position={ [ -305.9, 395.28, 159.68 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ -571.38, 490.94, 402.59 ] }
					rotation={ [ Math.PI / 2, 0, -Math.PI / 2 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_10.geometry }
						material={ materials[ "spot light" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_10_1.geometry }
						material={ materials[ "white spot" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.balcony_metal_floor_4.geometry }
					material={ materials[ "balcony metal" ] }
					position={ [ -123.05, 433.07, 435.69 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wood_plate_floor_4.geometry }
					material={ materials.wood }
					position={ [ 255.14, 397.44, 566.07 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.balcony_glass_floor_4.geometry }
					material={ materials[ "balcony glass" ] }
					position={ [ 535.89, 398.23, 570.11 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.ceiling_floor_4.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 500.79, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_3.geometry }
					material={ materials[ "window glass" ] }
					position={ [ -529.3, 772.2, 425.47 ] }
			/>
			<group
					position={ [ -328.13, 256.3, 237.99 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_16.geometry }
						material={ materials.wood }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_16_1.geometry }
						material={ materials[ "white wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_16_2.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.floor_3.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 277.95, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.ceiling_floor_3.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 389.37, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_3.geometry }
					material={ materials[ "white wall" ] }
					position={ [ -305.9, 283.86, 159.68 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ -606.81, 359.26, 612.84 ] }
					rotation={ [ 0, -Math.PI / 2, 0 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_20.geometry }
						material={ materials[ "black wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_20_1.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_3.geometry }
					material={ materials[ "window metal" ] }
					position={ [ -772.85, 772.2, 426.45 ] }
					rotation={ [ Math.PI, 0, -Math.PI ] }
			/>
			<group
					position={ [ -149.57, 382.87, 464.29 ] }
					rotation={ [ Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ 1.79, 1.79, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_22.geometry }
						material={ materials[ "spot light" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_22_1.geometry }
						material={ materials[ "white spot" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.balcony_metal_floor_3.geometry }
					material={ materials[ "balcony metal" ] }
					position={ [ -123.05, 321.65, 435.69 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.balcony_glass_floor_3.geometry }
					material={ materials[ "balcony glass" ] }
					position={ [ -123.05, 286.22, 434.9 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wood_plate_floor_3.geometry }
					material={ materials.wood }
					position={ [ 667.93, 286.81, 529.12 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.lower_cap.geometry }
					material={ materials[ "black wall" ] }
					position={ [ 90.31, 249.61, 24.97 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.ceiling_floor_7.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 842.91, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.top_cap.geometry }
					material={ materials[ "black wall" ] }
					position={ [ 90.31, 848.82, 24.97 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_7.geometry }
					material={ materials[ "white wall" ] }
					position={ [ -305.9, 729.53, 159.68 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.balcony_metal_floor_7.geometry }
					material={ materials[ "balcony metal" ] }
					position={ [ 667.94, 764.96, -354.67 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
			/>
			<group
					position={ [ -149.57, 828.54, 464.29 ] }
					rotation={ [ Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ 1.79, 1.79, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_31.geometry }
						material={ materials[ "spot light" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_31_1.geometry }
						material={ materials[ "white spot" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.balcony_glass_floor_7.geometry }
					material={ materials[ "balcony glass" ] }
					position={ [ 58.63, 731.89, -520.12 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wood_plate_floor_7.geometry }
					material={ materials.wood }
					position={ [ 410.2, 731.69, -381.01 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_7.geometry }
					material={ materials[ "window metal" ] }
					position={ [ 723.34, 981.69, 306.05 ] }
					rotation={ [ -Math.PI, 0, -Math.PI ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_7.geometry }
					material={ materials[ "window glass" ] }
					position={ [ -442.85, 1217.87, -510.69 ] }
					rotation={ [ -Math.PI, 0, -Math.PI ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.black_frame_floor_7.geometry }
					material={ materials[ "black wall" ] }
					position={ [ 165.47, 773.42, -441.92 ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<group
					position={ [ 621.51, 235.38, -329.32 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_37.geometry }
						material={ materials.wood }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_37_1.geometry }
						material={ materials[ "white wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_37_2.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<group
					position={ [ -328.13, 590.55, 237.99 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_38.geometry }
						material={ materials.wood }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_38_1.geometry }
						material={ materials[ "white wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_38_2.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.ceiling_floor_6.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 723.62, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_6.geometry }
					material={ materials[ "white wall" ] }
					position={ [ -305.9, 618.11, 159.68 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ 673.74, 715.75, -561.61 ] }
					rotation={ [ 0, Math.PI / 2, 0 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_41.geometry }
						material={ materials[ "black wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_41_1.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.balcony_metal_floor_6.geometry }
					material={ materials[ "balcony metal" ] }
					position={ [ 667.94, 653.54, -354.67 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
			/>
			<group
					position={ [ 85.15, 717.13, -549.51 ] }
					rotation={ [ Math.PI / 2, 0, Math.PI / 2 ] }
					scale={ [ 1.79, 1.79, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_43.geometry }
						material={ materials[ "spot light" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_43_1.geometry }
						material={ materials[ "white spot" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_6.geometry }
					material={ materials[ "window metal" ] }
					position={ [ 643.08, 870.28, 306.05 ] }
					rotation={ [ -Math.PI, 0, -Math.PI ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wood_plate_floor_6.geometry }
					material={ materials.wood }
					position={ [ 574.3, 620.28, -381.01 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_6.geometry }
					material={ materials[ "window glass" ] }
					position={ [ 677.48, 870.28, 305.06 ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.balcony_glass_floor_6.geometry }
					material={ materials[ "balcony glass" ] }
					position={ [ 443.08, 621.06, -385.05 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI ] }
			/>
			<group position={ [ -438.52, 539.92, 412.52 ] }>
				<mesh
						castShadow
						geometry={ nodes.mesh_48.geometry }
						material={ materials[ "black wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_48_1.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_5.geometry }
					material={ materials[ "window glass" ] }
					position={ [ -703, 995.04, 425.47 ] }
			/>
			<group
					position={ [ -328.13, 479.13, 237.99 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_50.geometry }
						material={ materials.wood }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_50_1.geometry }
						material={ materials[ "white wall" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_50_2.geometry }
						material={ materials[ "white balcony" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_5.geometry }
					material={ materials[ "window metal" ] }
					position={ [ -632.7, 995.04, 426.45 ] }
					rotation={ [ Math.PI, 0, -Math.PI ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.ceiling_floor_5.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 612.2, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_5.geometry }
					material={ materials[ "white wall" ] }
					position={ [ -305.9, 506.69, 159.68 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ -571.38, 602.36, 402.59 ] }
					rotation={ [ Math.PI / 2, 0, -Math.PI / 2 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_54.geometry }
						material={ materials[ "spot light" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_54_1.geometry }
						material={ materials[ "white spot" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.balcony_glass_floor_5.geometry }
					material={ materials[ "balcony glass" ] }
					position={ [ -600.23, 506.69, 405.89 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI / 2 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.balcony_metal_floor_5.geometry }
					material={ materials[ "balcony metal" ] }
					position={ [ -123.05, 544.49, 435.69 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wood_plate_floor_5.geometry }
					material={ materials.wood }
					position={ [ 237.19, 510.43, 566.07 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ 423.93, 63.58, 542.97 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_58.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_58_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_58_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_58_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ 456.02, 59.9, -282.27 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_59.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_59_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_59_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_59_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ -12.09, 59.9, 408.3 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_60.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_60_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_60_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_60_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ -262.99, 63.58, -418.71 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_61.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_61_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_61_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_61_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ 520.19, 61.87, -281.29 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_62.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_62_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_62_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_62_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ 359.76, 65.55, 541.99 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_63.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_63_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_63_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_63_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.interior_wall_floor_1.geometry }
					material={ materials[ "white wall" ] }
					position={ [ 63.36, 248.03, 24.47 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.window_glass_floor_1.geometry }
					material={ materials[ "window glass 1floor" ] }
					position={ [ -437.25, 288.98, -329.45 ] }
					rotation={ [ -Math.PI, 0, -Math.PI ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.floor_1.geometry }
					material={ materials.grey }
					position={ [ 90.31, 0, 62.37 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.wall_floor_1.geometry }
					material={ materials[ "black brick" ] }
					position={ [ -513.64, 118.11, 312.86 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
			<group
					position={ [ -198.82, 65.55, -417.73 ] }
					rotation={ [ -Math.PI / 2, 0, -Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_68.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_68_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_68_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_68_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<group
					position={ [ -76.26, 61.87, 407.31 ] }
					rotation={ [ -Math.PI / 2, 0, Math.PI / 2 ] }
					scale={ [ -1, 1, 1 ] }
			>
				<mesh
						castShadow
						geometry={ nodes.mesh_69.geometry }
						material={ materials[ "window metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_69_1.geometry }
						material={ materials.Rubber }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_69_2.geometry }
						material={ materials[ "balcony metal" ] }
				/>
				<mesh
						castShadow
						geometry={ nodes.mesh_69_3.geometry }
						material={ materials[ "window glass 1floor" ] }
				/>
			</group>
			<mesh
					castShadow
					geometry={ nodes.window_frame_floor_1.geometry }
					material={ materials[ "window metal" ] }
					position={ [ -598.91, 288.98, -330.43 ] }
					scale={ [ -1, 1, 1 ] }
			/>
			<mesh
					castShadow
					geometry={ nodes.black_frame_floor_1.geometry }
					material={ materials[ "black wall" ] }
					position={ [ 90.31, 118.11, 62.37 ] }
					rotation={ [ -Math.PI / 2, 0, 0 ] }
			/>
		</group>
	</> )
}

export default Z_Building
