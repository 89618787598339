//region Imports
import { Loader }                                                from '@react-three/drei'
import { Canvas }                                                from '@react-three/fiber'
import { createUseGesture, pinchAction, wheelAction }            from '@use-gesture/react'
import cn                                                        from 'classnames'
import { gsap, Power0 }                                          from 'gsap'
import { GSDevTools }                                            from 'gsap/GSDevTools'
import { Leva }                                                  from 'leva'
import Slider                                                    from 'rc-slider'
import 'rc-slider/assets/index.css';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import styles                                                    from '../../styles/Z_Building.module.sass'
import Z_Building_Canvas                                         from './Z_Building_Canvas'
//endregion

//region Custom range with tooltip
const Z_Range = Slider.createSliderWithTooltip( Slider.Range )
//endregion

//region Default values
// Default surfaces values
const defaultSurfacesValues = {
	min: 10,
	max: 100
}
// Default prices values
const defaultPricesValues   = {
	min: 100,
	max: 300
}
// Default building z position value
const defaultBuildingScale  = .75
const maxBuildingScale      = .5
const pinchMin              = 1
const pinchMax              = 3
const wheelMin              = -2_000
const wheelMax              = 0
//endregion

//region GSAP
gsap.registerPlugin( GSDevTools )
//endregion

const useGesture = createUseGesture( [ pinchAction, wheelAction ] )

export default function Z_Building_Page( { setPageToShow } ) {
	
	//region References
	const tutorialCircleRef  = useRef()
	const tutorialCircle2Ref = useRef()
	const canvasRef          = useRef()
	//endregion
	
	//region States
	const [ surfaceValues, setSurfaceValues ] = useState( defaultSurfacesValues )
	const [ priceValues, setPriceValues ]     = useState( defaultPricesValues )
	
	const [ selectedFilter, setSelectedFilter ]                   = useState( null )
	const [ selectedNumberOfRoomsId, setSelectedNumberOfRoomsId ] = useState( null )
	
	const [ modelLoaded, setModelLoaded ] = useState( false )
	
	const [ buildingScale, setBuildingScale ] = useState( defaultBuildingScale )
	
	const [ pinching, setPinching ] = useState( false )
	
	const [ selectedApartment, setSelectedApartment ] = useState( null )
	//endregion
	
	//region Memos
	// Set filters values
	const filters = useMemo(
			() => {
				return [
					{
						name:  'rooms',
						text:  'Pièces',
						class: styles.rooms,
					}, {
						name:  'surface',
						text:  'Surface',
						class: styles.surface,
					}, {
						name:  'price',
						text:  'Prix',
						class: styles.price,
					},
				]
			},
			[]
	)
	//endregion
	
	//region Gestures
	useGesture(
			{
				onPinchStart: () => {
					setPinching( true )
				},
				onPinchEnd:   () => {
					setPinching( false )
				},
				onPinch:      ( {
					                offset: [ s ],
				                } ) => {
					const currentCameraZoom = defaultBuildingScale + ( maxBuildingScale * ( s - pinchMin ) / ( pinchMax
					                                                                                           - pinchMin ) )
					setBuildingScale( currentCameraZoom )
				},
				onWheel:      ( {
					                event,
					                offset: [ , oy ],
				                } ) => {
					event.preventDefault()
					
					const currentCameraZoom = defaultBuildingScale + ( maxBuildingScale * ( oy / ( wheelMin ) ) )
					setBuildingScale( currentCameraZoom )
				},
			},
			{
				target: canvasRef,
				pinch:  {
					scaleBounds: {
						min: pinchMin,
						max: pinchMax,
					},
					rubberband:  true
				},
				wheel:  {
					bounds:       {
						top:    wheelMin,
						bottom: wheelMax,
					},
					eventOptions: {
						passive: false,
					},
					rubberband:   true,
				},
			}
	)
	//endregion
	
	//region Handlers
	// Component load
	useEffect(
			() => {
				// Set body overflow to hidden
				document.body.style.overflow        = 'hidden'
				document.body.style.backgroundColor = '#eef3f7'
				
				
				//region Deactivate pinch gesture
				const handler = e => e.preventDefault()
				document.addEventListener(
						'gesturestart',
						handler
				)
				document.addEventListener(
						'gesturechange',
						handler
				)
				document.addEventListener(
						'gestureend',
						handler
				)
				return () => {
					document.removeEventListener(
							'gesturestart',
							handler
					)
					document.removeEventListener(
							'gesturechange',
							handler
					)
					document.removeEventListener(
							'gestureend',
							handler
					)
				}
				//endregion
			},
			[]
	)
	
	// Model loaded -> play animation
	useEffect(
			() => {
				if ( modelLoaded ) {
					// Tutorial animation
					const tutorialTimeline = gsap.timeline( {
						                                        id:       'tutorialTimelineID',
						                                        defaults: {
							                                        duration: 1,
							                                        ease:     Power0.easeNone
						                                        }
					                                        } )
					
					let opacity_appear_duration    = .2
					let moving_duration            = 1
					let opacity_disappear_duration = moving_duration - opacity_appear_duration
					
					//region Swipe left
					for ( let i = 0; i < 3; i++ ) {
						tutorialTimeline
								// Move left
								.fromTo(
										tutorialCircleRef.current,
										{
											opacity: 0,
											left:    '30%',
											top:     '50%',
										},
										{
											// Delay first animation
											delay:    i === 0 ? 1 : 0,
											duration: moving_duration,
											left:     '70%'
										},
								)
								// Appear - Opacity
								.to(
										tutorialCircleRef.current,
										{
											duration: opacity_appear_duration,
											opacity:  .8,
										},
										'<'
								)
								// Disappear - Opacity
								.to(
										tutorialCircleRef.current,
										{
											duration: opacity_disappear_duration,
											opacity:  0,
										},
										'>'
								)
					}
					//endregion
					
					//region Pinch
					for ( let i = 0; i < 3; i++ ) {
						tutorialTimeline
								.addLabel( `vertical_start_${ i }` )
								// Place center
								.set(
										tutorialCircleRef.current,
										{
											opacity: 0,
											top:     '48%',
											left:    '50%',
										}
								)
								.set(
										tutorialCircle2Ref.current,
										{
											opacity: 0,
											top:     '52%',
											left:    '50%',
										}
								)
								// Move top up
								.to(
										tutorialCircleRef.current,
										{
											duration: moving_duration,
											top:      '30%',
										},
										'>'
								)
								// Move bottom down
								.to(
										tutorialCircle2Ref.current,
										{
											duration: moving_duration,
											top:      '70%',
										},
										'<'
								)
								//Appear
								.to(
										tutorialCircleRef.current,
										{
											opacity:  1,
											duration: opacity_appear_duration
										},
										'<'
								)
								.to(
										tutorialCircle2Ref.current,
										{
											opacity:  1,
											duration: opacity_appear_duration
										},
										'<'
								)
								
								
								// Disappear
								.to(
										tutorialCircleRef.current,
										{
											duration: opacity_disappear_duration,
											opacity:  0,
										},
										'>'
								)
								.to(
										tutorialCircle2Ref.current,
										{
											duration: opacity_disappear_duration,
											opacity:  0,
										},
										'<'
								)
					}
					//endregion
					
					return () => {
						tutorialTimeline.kill()
					}
				}
			},
			[ modelLoaded ]
	)
	//endregion
	
	return ( <div className={ styles.home }>
		{ /* region Overlay */ }
		<div className={ styles.overlay }>
			<div className={ styles.top_information }>
				<div className={ styles.title }>Green Eko</div>
				<div className={ styles.rooms_and_price }>2 à 4 pièces<br />à partir de 100 000€</div>
			</div>
			
			<div className={ styles.bottom }>
				<div className={ styles.filters }>
					
					<div className={ styles.selected_filter }>
						<div
								className={ cn(
										styles.rooms,
										{ [ styles.selected ]: selectedFilter === 'rooms' }
								) }
						>
							{ [ ...Array( 3 ) ].map( ( _, index ) => {
								return ( <button
										key={ index }
										onClick={ () => {
											setSelectedNumberOfRoomsId( index )
										} }
										className={ cn( { [ styles.selected ]: selectedNumberOfRoomsId === index } ) }
								>
										<span
												className={ styles.text }
										>
											{ index + 2 }p
										</span>
								</button> )
							} ) }
						</div>
						<div
								className={ cn(
										styles.surface,
										{
											[ styles.selected ]: selectedFilter === 'surface'
										}
								) }
						>
							<Z_Range
									className={ styles.slider }
									{ ...defaultSurfacesValues }
									marks={ {
										10:  { label: "10\u00A0m²" },
										100: { label: '100\u00A0m²' }
									} }
									defaultValue={ [ defaultSurfacesValues.min, defaultSurfacesValues.max ] }
									onAfterChange={ ( value ) => {
										setSurfaceValues( {
											                  min: value[ 0 ],
											                  max: value[ 1 ],
										                  } )
										setSurfaceValues( {
											                  min: value[ 0 ],
											                  max: value[ 1 ],
										                  } )
									} }
									tipFormatter={ ( value ) => `${ value }\u00A0m²` }
									dotStyle={ {
										height:     '12px',
										width:      '12px',
										bottom:     '-6px',
										marginLeft: '-6px'
									} }
									railStyle={ {
										background: 'linear-gradient(90deg, #D2DCFE 11.18%, #D2DDFE 104.61%)',
										height:     '8px'
									} }
									trackStyle={ [
										{
											background: 'linear-gradient(90deg, #808CEE -4.29%, #5C6CE4 100.2%)',
											height:     '8px'
										}, {
											background: 'linear-gradient(90deg, #D2DCFE 11.18%, #D2DDFE 104.61%)',
											height:     '8px'
										}
									] }
									handleStyle={ [
										{
											background: 'radial-gradient(77.78% 77.78% at 83.33% 83.33%, #596AE3 0%, #7E8AEE 100%)',
											border:     'none',
											height:     '24px',
											width:      '24px',
											marginTop:  '-8px'
										}, {
											background: 'radial-gradient(77.78% 77.78% at 83.33% 83.33%, #596AE3 0%, #7E8AEE 100%)',
											border:     'none',
											height:     '24px',
											width:      '24px',
											marginTop:  '-8px'
										}
									] }
							/>
						</div>
						<div
								className={ cn(
										styles.price,
										{ [ styles.selected ]: selectedFilter === 'price' }
								) }
						>
							<Z_Range
									className={ styles.slider }
									{ ...defaultPricesValues }
									marks={ {
										100: { label: "100\u00A0k\u00A0€" },
										300: { label: '300\u00A0k\u00A0€' }
									} }
									defaultValue={ [ defaultPricesValues.min, defaultPricesValues.max ] }
									onAfterChange={ ( value ) => {
										setPriceValues( {
											                min: value[ 0 ],
											                max: value[ 1 ],
										                } )
										setPriceValues( {
											                min: value[ 0 ],
											                max: value[ 1 ],
										                } )
									} }
									tipFormatter={ ( value ) => `${ value }\u00A0k\u00A0€` }
									dotStyle={ {
										height:     '12px',
										width:      '12px',
										bottom:     '-6px',
										marginLeft: '-6px'
									} }
									railStyle={ {
										background: 'linear-gradient(90deg, #D2DCFE 11.18%, #D2DDFE 104.61%)',
										height:     '8px'
									} }
									trackStyle={ [
										{
											background: 'linear-gradient(90deg, #808CEE -4.29%, #5C6CE4 100.2%)',
											height:     '8px'
										}, {
											background: 'linear-gradient(90deg, #D2DCFE 11.18%, #D2DDFE 104.61%)',
											height:     '8px'
										}
									] }
									handleStyle={ [
										{
											background: 'radial-gradient(77.78% 77.78% at 83.33% 83.33%, #596AE3 0%, #7E8AEE 100%)',
											border:     'none',
											height:     '24px',
											width:      '24px',
											marginTop:  '-8px'
										}, {
											background: 'radial-gradient(77.78% 77.78% at 83.33% 83.33%, #596AE3 0%, #7E8AEE 100%)',
											border:     'none',
											height:     '24px',
											width:      '24px',
											marginTop:  '-8px'
										}
									] }
							/>
						</div>
					</div>
					
					<div className={ styles.selectors }>
						{ filters.map( filter => {
							return ( <div
									key={ filter.name }
									className={ cn(
											filter.class,
											{
												[ styles.selected ]: filter.name === selectedFilter
											}
									) }
									onClick={ () => {
										setSelectedFilter( filter.name === selectedFilter ? null : filter.name )
									} }
							>
											<span className={ styles.text }>
												{ filter.text }
											</span>
							</div> )
						} ) }
					</div>
				</div>
				
				<button
						className={ styles.show_apartment }
						onClick={ () => {
							setPageToShow( 'room-page' )
						} }
						disabled={ selectedApartment === null }
				>
					Afficher l'appartement
				</button>
			</div>
			
			<div className={ styles.tutorial }>
				<div
						className={ styles.circle }
						ref={ tutorialCircleRef }
				></div>
				<div
						className={ styles.circle }
						ref={ tutorialCircle2Ref }
				></div>
			</div>
		</div>
		{ /*endregion*/ }
		
		{ /* Leva */ }
		<Leva
				collapsed={ true }
				hidden={ !window.debug }
		/>
		
		{/* region Canvas */ }
		<Canvas
				ref={ canvasRef }
				className={ styles.canvas }
				shadows={ true }
				camera={ { near: .5 } }
				flat
		>
			{/* Building */ }
			<Suspense fallback={ null }>
				<Z_Building_Canvas
						setModelLoaded={ setModelLoaded }
						buildingScale={ buildingScale }
						pinching={ pinching }
						selectedNumberOfRoomsId={ selectedNumberOfRoomsId }
						selectedApartment={ selectedApartment }
						setSelectedApartment={ setSelectedApartment }
						surfaceValues={ surfaceValues }
				/>
			</Suspense>
		</Canvas>
		{/* endregion */ }
		
		{/* Loader shown while canvas loading building model */ }
		<Loader />
	</div> )
}

