//region Imports
import { animated, useSpring }               from '@react-spring/three'
import { Environment, PresentationControls } from '@react-three/drei'
import { useThree }                          from '@react-three/fiber'
import { useControls }                       from 'leva'
import { Perf }                              from 'r3f-perf'
import { useEffect, useRef }                 from 'react'
import Z_Building                            from './Z_Building'
import Z_Building_Appartments                from './Z_Building_Appartments'
import Z_Building_Trees                      from './Z_Building_Trees'
//endregion

const Z_Building_Canvas = ( {
	                            setModelLoaded,
	                            buildingScale,
	                            pinching,
	                            selectedNumberOfRoomsId,
	                            surfaceValues,
	                            selectedApartment,
	                            setSelectedApartment,
	
	                            children
                            } ) => {
	//region References
	const spotLightRef = useRef()
	//endregion
	
	//region Springs
	const [ currentBuildingScale, currentBuildingScaleApi ] = useSpring( () => ( {
		value: 0,
	} ) )
	//endregion
	
	//region Camera
	const { camera } = useThree()
	//endregion
	
	//region LEVA Properties
	//region Presentation controls props
	const [ { ...presentationControlsProps }, setPresentationControlsProps ] = useControls(
			'Presentation controls',
			() => ( {
				snap:     false,
				polar:    [ -.3, Math.PI / 8 ],
				azimuth:  [ -Math.PI / 2, Math.PI / 2 ],
				zoom:     .8,
				rotation: [ .5, -.6, 0 ],
			} ),
			{ collapsed: true }
	)
	//endregion
	
	//region Spotlight props
	const spotLightProps = useControls(
			'SpotLight',
			{
				position:  [ -1, 15, -2 ],
				intensity: 1,
			},
			{ collapsed: true }
	)
	//endregion
	
	//region Building props
	const buildingProps = useControls(
			'Building',
			{
				position: [ 0, 0, 0 ],
			},
			{ collapsed: true }
	)
	//endregion
	//endregion
	
	//region Handlers
	/**
	 * Set camera fov from the window width
	 */
	const setCameraFovFromWindowWidth = () => {
		const isMobile = window.innerWidth < 768
		camera.fov     = isMobile ? 110 : 75
	}
	
	// Component load
	useEffect(
			() => {
				setCameraFovFromWindowWidth()
				
				// Change camera fov on window resize
				window.addEventListener(
						'resize',
						() => {
							setCameraFovFromWindowWidth()
						}
				)
				
				setPresentationControlsProps( {
					                              snap:     false,
					                              polar:    [ -.3, Math.PI / 8 ],
					                              azimuth:  [
						                              -Math.PI / 2 + .6, Math.PI / 2 - .6
					                              ],
					                              zoom:     .8,
					                              rotation: [ .5, -.6, 0 ],
				                              } )
				
			},
			[]
	)
	
	// Change building scale
	useEffect(
			() => {
				currentBuildingScaleApi.start( { value: buildingScale } )
			},
			[ buildingScale ]
	)
	//endregion
	
	return ( <>
		{ /* region Performances */ }
		{ window.debug && <Perf position={ 'top-left' } /> }
		{ /* endregion */ }
		
		<PresentationControls
				global
				cursor={ false }
				
				{ ...presentationControlsProps }
		>
			{/* Environment */ }
			<Environment
					preset={ 'city' }
			/>
			
			{/* Building model */ }
			<animated.group
					scale={ currentBuildingScale.value }
					position={ [ -.5, 0, -1 ] }
			>
				<spotLight
						ref={ spotLightRef }
						{ ...spotLightProps }
						castShadow
				/>
				
				<Z_Building
						setModelLoaded={ setModelLoaded }
						buildingScale={ buildingScale }
						scale={ .0025 }
						{ ...buildingProps }
				/>
				<Z_Building_Appartments
						selectedNumberOfRoomsId={ selectedNumberOfRoomsId }
						surfaceValues={ surfaceValues }
						selectedApartment={ selectedApartment }
						setSelectedApartment={ setSelectedApartment }
				/>
				<Z_Building_Trees />
			</animated.group>
		</PresentationControls>
		{/* <OrbitControls makeDefault /> */ }
	</> )
}

export default Z_Building_Canvas
