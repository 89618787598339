//region Imports
import { animated, useSpring }                        from '@react-spring/three'
import { Environment, PresentationControls }          from '@react-three/drei'
import { useThree }                                   from '@react-three/fiber'
import { Bloom, EffectComposer }                      from '@react-three/postprocessing'
import { createUseGesture, pinchAction, wheelAction } from '@use-gesture/react'
import { gsap }                                       from 'gsap'
import { button, folder, useControls }                from 'leva'
import { Suspense, useEffect, useRef, useState }      from 'react'
import Z_Apartment                                    from '../appartment/Z_Apartment'
//endregion

//region Default values
// Polar
const defaultPolar = [
	Math.PI / 4, Math.PI / 2
]
// Azimuth
// 2 turn max (one turn on each side)
const defaultAzimuth = [ -2 * Math.PI, 2 * Math.PI ]
// Zoom
const generalZoom    = 1.5
const roomsZoom      = 1

//region Camera zoom
const defaultCameraZoom = 1
const maxCameraZoom     = 1.8
const pinchMin          = 1
const pinchMax          = 3
const wheelMin          = -2_000
const wheelMax          = 0
//endregion
//endregion

const useGesture = createUseGesture( [ pinchAction, wheelAction ] )

const Z_Room_Canvas = ( {
	                        room,
	                        canvasRef
                        } ) => {
	//region References
	const groupRef = useRef()
	//endregion
	
	//region States
	const [ modelLoaded, setModelLoaded ] = useState( false )
	
	const [ cameraZoom, setCameraZoom ] = useState( 1.3 )
	const [ pinching, setPinching ]     = useState( false )
	//endregion
	
	//region Gestures
	useGesture(
			{
				onPinchStart: () => {
					setPinching( true )
				},
				onPinchEnd:   () => {
					setPinching( false )
				},
				onPinch:      ( {
					                offset: [ s ],
				                } ) => {
					const currentCameraZoom = defaultCameraZoom + ( maxCameraZoom * ( s - pinchMin ) / ( pinchMax
					                                                                                     - pinchMin ) )
					setCameraZoom( currentCameraZoom )
				},
				onWheel:      ( {
					                event,
					                offset: [ , oy ],
				                } ) => {
					event.preventDefault()
					
					const currentCameraZoom = defaultCameraZoom + ( maxCameraZoom * ( oy / ( wheelMin ) ) )
					setCameraZoom( currentCameraZoom )
				},
			},
			{
				target: canvasRef,
				pinch:  {
					scaleBounds: {
						min: pinchMin,
						max: pinchMax
					},
					rubberband:  true,
				},
				wheel:  {
					bounds:       {
						top:    wheelMin,
						bottom: wheelMax,
					},
					eventOptions: {
						passive: false,
					},
					rubberband:   true,
				},
			}
	)
	//endregion
	
	//region Springs
	// Default value is 0 to animate (scale up) when model appears
	const [ currentCameraZoom, currentCameraZoomApi ] = useSpring( () => ( {
		value: 0,
	} ) )
	//endregion
	
	//region Camera
	const { camera } = useThree()
	//endregion
	
	//region LEVA - Properties
	//region Environment props
	const { ...environmentProps } = useControls(
			'Environment',
			{
				resolution: 32,
				preset:     {
					options: [
						'sunset',
						'dawn',
						'night',
						'warehouse',
						'forest',
						'apartment',
						'studio',
						'city',
						'park',
						'lobby',
					]
				},
				intensity:  {
					value: .5,
					min:   0,
					max:   1,
					step:  .1,
				},
			}
	)
	//endregion
	
	//region Effects props (Bloom)
	const {
		      activateEffects,
		      mipmapBlur,
		      intensity,
		      luminanceThreshold
	      } = useControls(
			'Effects',
			{
				activateEffects: false,
				Bloom:           folder( {
					                         mipmapBlur:         true,
					                         intensity:          {
						                         value: .1,
						                         min:   0,
						                         max:   1,
						                         step:  .01
					                         },
					                         luminanceThreshold: 0
				                         } )
			}
	)
	//endregion
	
	//region Presentation controls props
	const [ { ...presentationControlsProps }, setPresentationControlsProps ] = useControls(
			'Presentation controls',
			() => ( {
				snap:     true,
				polar:    defaultPolar,
				azimuth:  defaultAzimuth,
				zoom:     generalZoom,
				rotation: [ 0, Math.PI, 0 ],
				log:      button( () => {
					console.log( presentationControlsProps.rotation )
				} )
			} )
	)
	//endregion
	
	const [ { ...groupProps }, setGroupProps ] = useControls(
			'General group',
			() => ( {
				'position-y': 0,
			} )
	)
	//endregion
	
	//region Handlers
	//region Room changed
	useEffect(
			() => {
				switch ( room?.id ) {
					case 1:
						setPresentationControlsProps( {
							                              polar:    [ -.4, Math.PI / 2 - .8 ],
							                              azimuth:  defaultAzimuth,
							                              zoom:     generalZoom,
							                              rotation: [ .8, Math.PI, 0 ],
						                              } )
						break
						// Salon
					case 4:
						setPresentationControlsProps( {
							                              polar:    [
								                              Math.PI / 4, Math.PI / 2 + .1
							                              ],
							                              azimuth:  defaultAzimuth,
							                              zoom:     roomsZoom,
							                              rotation: [ -.10, Math.PI, 0 ],
						                              } )
						break
						// Salle de bain
					case 6:
						setPresentationControlsProps( {
							                              polar:    [
								                              Math.PI / 4, Math.PI / 2 - .3
							                              ],
							                              azimuth:  defaultAzimuth,
							                              zoom:     roomsZoom,
							                              rotation: [ .3, Math.PI, 0 ],
						                              } )
						break
					default:
						setPresentationControlsProps( {
							                              polar:    defaultPolar,
							                              azimuth:  defaultAzimuth,
							                              zoom:     roomsZoom,
							                              rotation: [ 0, Math.PI, 0 ],
						                              } )
						break
				}
				
				setPresentationControlsProps( { snap: !( room?.id === 1 ) } )
				
				if ( room && room.group_position_y ) {
					gsap.to(
							groupRef.current.position,
							{
								y:        room.group_position_y,
								duration: .5
							}
					)
				}
			},
			[ room ]
	)
	//endregion
	
	//region Component load
	const setCameraFovFromWindowWidth = () => {
		const isMobile = window.innerWidth < 768
		camera.fov     = isMobile ? 80 : 75
	}
	
	useEffect(
			() => {
				setCameraFovFromWindowWidth()
				
				// Change camera fov on window resize
				window.addEventListener(
						'resize',
						() => {
							setCameraFovFromWindowWidth()
						}
				)
				
				//region Deactivate pinch gesture
				const handler = e => e.preventDefault()
				document.addEventListener(
						'gesturestart',
						handler
				)
				document.addEventListener(
						'gesturechange',
						handler
				)
				document.addEventListener(
						'gestureend',
						handler
				)
				return () => {
					document.removeEventListener(
							'gesturestart',
							handler
					)
					document.removeEventListener(
							'gesturechange',
							handler
					)
					document.removeEventListener(
							'gestureend',
							handler
					)
				}
				//endregion
			},
			[]
	)
	//endregion
	
	//region Camera zoom
	useEffect(
			() => {
				// Only if the model is loaded
				if ( modelLoaded ) {
					currentCameraZoomApi.start( { value: cameraZoom } )
				}
			},
			[ cameraZoom, modelLoaded ]
	)
	//endregion
	//endregion
	
	return ( <animated.group
			position={ [ 0, 0, -10 ] }
			{ ...groupProps }
			scale={ currentCameraZoom.value }
			ref={ groupRef }
	>
		<PresentationControls
				global
				//				enabled={ !pinching }
				
				{ ...presentationControlsProps }
		>
			{/* Environment */ }
			<Environment
					{ ...environmentProps }
					frames={ 1 }
			/>
			
			{/* region Effects - Bloom */ }
			{/* Bloom effect if effects are active */ }
			{ activateEffects && <EffectComposer>
				<Bloom
						mipmapBlur={ mipmapBlur }
						intensity={ intensity }
						luminanceThreshold={ luminanceThreshold }
				/>
			</EffectComposer> }
			{/* endregion */ }
			
			{/* Apartment model */ }
			<Suspense fallback={ null }>
				<Z_Apartment
						room={ room }
						envMapIntensity={ environmentProps.intensity }
						setModelLoaded={ setModelLoaded }
						roomLightDistanceScale={ cameraZoom }
				/>
			</Suspense>
		</PresentationControls>
	</animated.group> )
}

export default Z_Room_Canvas
